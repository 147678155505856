import { useTranslation } from 'react-i18next';
import { setNotification } from '../../../../api-calls/SetNotification';
import { RebatePopup } from '../../../../components/RebatePopup'
import { NOTIFICATION } from '../../../../constants';
import { useFeatureFlagsConfig } from '../../../../hooks/useFeatureFlags';
import { datalayerPush } from '../../../../lib/gtm';
import { GTM_ACTIONS } from '../../../../config/gtmActions';

const RebatePopupWrapper = ({fetchNotificationData,
    updateNotificationStatus}:
    {fetchNotificationData:()=>void,
        updateNotificationStatus:(value:boolean) => void
    }) => {
  const { t } = useTranslation();
    const { rebateNotification_url } = useFeatureFlagsConfig();
    const { setNotificationData } = setNotification({notificationTypeId: NOTIFICATION.REBATE_NOTIFICTION_TYPE, showNotification: false});

    const rebateObj =
    {
        id:NOTIFICATION.REBATE_NOTIFICTION_TYPE?.toString() || "1",
        header: t(NOTIFICATION.REBATE_HEADER),
        body:  t(NOTIFICATION.REBATE_BODY),
        ctaOneText: t(NOTIFICATION.REBATE_PRIMARY_ACTION),
        ctaOneAction: async (isChecked: boolean) => {
            if (isChecked) {
                datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_REBATE_CLOSE_CHEKED);
                datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_REBATE_DISMISS);
                await setNotificationData();
                fetchNotificationData();
            }else{
                datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_REBATE_DISMISS);
                updateNotificationStatus(true);
            }
        },
        ctaOneStyle: "bg-white hover:bg-blue-900 text-blue-900 font-semibold hover:text-white py-2 px-3 border border-blue-900 hover:border-transparent rounded mb-2 mr-2 lg:mb-0 text-sm",
        ctaTwoText: t(NOTIFICATION.REBATE_SECONDARY_ACTION),
        ctaTwoAction: async () => {
            //clicked on get rebate
            datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_GET_REBATE);
            await setNotificationData();
            fetchNotificationData();
            window.open(rebateNotification_url, '_blank');
        },
        ctaTwoStyle: "px-3 py-2 text-white font-semibold bg-blue-900 rounded mr-0 text-sm",
        showCheckbox: true,
        checkboxLabel: t(NOTIFICATION.REBATE_CHECKBOX_LABEL),
        position: "right",
        closeCtaAction: async (isChecked: boolean) => {
            if (isChecked) {
                datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_REBATE_CLOSE_CHEKED);
                datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_REBATE_CLOSE);
                await setNotificationData();
                fetchNotificationData();
            }else{
                datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_REBATE_CLOSE);
                updateNotificationStatus(true);
            }
        }
    }

    return (
        <RebatePopup
            rebateObj={rebateObj} />
    )
}

export default RebatePopupWrapper;