import { useEffect, useRef, useState } from "react";
import { fetchNotifications } from "../../api-calls/FetchNotifications";
import AccountSettingsWrapper from "./components/AccountSettingsWrapper";
import { MyPetsWrapper } from "./components/MyPetsWrapper";
import RebatePopupWrapper from "./components/RebatePopupWrapper";
import WelcomeUserWrapper from "./components/WelcomeUserWrapper";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";
import { NOTIFICATION } from "../../constants";

const AccountHome = () => {
  const { fetchNotificationData, data } = fetchNotifications(
    NOTIFICATION.REBATE_NOTIFICTION_TYPE
  );
  const { rebateNotification } = useFeatureFlagsConfig();

  const initialized = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [hideNotification,setNotificationStatus ]= useState(false); 

  const updateNotificationStatus = (value:boolean) => {
    setNotificationStatus(value);
  }

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    if (!initialized.current) {
      initialized.current = true;
      fetchNotificationData();
    }
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  return (
    <div className="px-5 lg:px-0">
      <div className="hidden lg:block">
        <WelcomeUserWrapper />
      </div>
      {data?.data?.data?.showNotification && rebateNotification && !hideNotification &&(
        <div className="mb-8">
          <RebatePopupWrapper 
          fetchNotificationData={fetchNotificationData} 
          updateNotificationStatus={updateNotificationStatus}
          />
        </div>
      )}
      <div className="mb-6">
        <MyPetsWrapper />
      </div>
      <div className="mb-6">
        <AccountSettingsWrapper />
      </div>
    </div>
  );
};

AccountHome.defaultProps = {};

export default AccountHome;
