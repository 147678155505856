import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "ui-2";
import { usePromise } from "utils";
import { updateMilestone } from "../../../../api-calls/updateMilestone";
import * as Modal from "../../../../components/Modal";
import { useToastConfig } from "../../../../components/toast";
import { CANCEL, LIFESTAGE } from "../../../../constants/index";
import { Alert } from "../../../../icons/Alert";
import { useMilestones } from "./context";

export function DeleteMilestoneModal({
  petId,
  milestoneId,
}: {
  petId: string;
  milestoneId: string;
}) {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const [{ isPending, isSuccess, isError }, { exec: saveMilestone }] =
    usePromise(updateMilestone);

  const { refetchMilestones } = useMilestones();

  useEffect(() => {
    if (isSuccess) {
      showToast({
        description: t(LIFESTAGE.DELETE_SUCCESS),
        status: "success",
      });
      refetchMilestones();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast({
        description: t(LIFESTAGE.UPDATE_FAILED),
        status: "error",
      });
    }
  }, [isError]);

  const handleDelete = useCallback(
    function handleDelete() {
      saveMilestone({
        petId,
        petMilestoneId: milestoneId,
        completedDate: null,
      });
    },
    [saveMilestone, petId, milestoneId]
  );

  return (
    <Modal.SideModal title={t(LIFESTAGE.DELETE)}>
      <div className="w-full">
        <Modal.Description asChild>
          <div className="m-auto max-w-[322px] px-5 pt-6 text-center md:pt-8">
            <Alert className="m-auto mb-4 md:mb-8" />
            <h3 className="headline5 md:headline4 mb-4 md:mb-8">
              {t(LIFESTAGE.DELETE_WARNING)}
            </h3>
            <p className="body2 md:body1">{t(LIFESTAGE.DELETE_DESCRIPTION)}</p>
          </div>
        </Modal.Description>
        <Modal.Footer className="md:flex-row-reverse md:justify-start">
          <Button
            type="button"
            variant="dangerOutlined"
            classes="lg:label1 max-md:w-full"
            onClick={handleDelete}
            disabled={isPending}
          >
            {t(LIFESTAGE.DELETE)}
          </Button>
          <Modal.Cancel asChild>
            <Button
              type="button"
              variant="blueText"
              classes="lg:label1 max-md:w-full"
              disabled={isPending}
            >
              {t(CANCEL)}
            </Button>
          </Modal.Cancel>
        </Modal.Footer>
      </div>
    </Modal.SideModal>
  );
}
