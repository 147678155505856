import React, { useEffect, useState } from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { IconWarning, IconToastSuccess, IconToastCross, IconCloseLg } from "ui-2";
import { ToastStatesProps } from "./ToastTypes";

const toastTypes = {
  error: "bg-red-100 border-2 border-red-300 text-red-800",
  success: "bg-green-100 border-2 border-green-300 text-green-800",
  warning: "bg-orange-100 border-2 border-orange-400 text-orange-700",
};
const iconMap = {
  success: <IconToastSuccess />,
  error: <IconToastCross />,
  warning: <IconWarning />
}
const position = {
  topRight: "lg:left-auto lg:right-10",
  topLeft: "lg:right-auto lg:left-8",
  bottomLeft: "lg:right-auto lg:left-8",
  bottomRight: "lg:left-auto lg:right-8",
  top: "top-16",
  bottom: "bottom-20",
}
const ToastClose = ToastPrimitive.Close;

const Toast = ({ toastConfig, toastState, setToastState }: ToastStatesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    if (toastState) {
      setIsOpen(true);
      setShouldAnimate(true);

      const timer = setTimeout(() => {
        setIsOpen(false);
        setToastState(false);
      }, toastConfig?.duration || 10000);
      return () => clearTimeout(timer);
    } else {
      setIsOpen(false);
    }
  }, [toastState, toastConfig]);

  const animationClass = shouldAnimate ? (isOpen ? 'slide-in' : 'slide-out') : '';

  const handleClose = () => {
    setIsOpen(false);
    setToastState(false);
    setShouldAnimate(false);
  };

  return (
    <ToastPrimitive.Provider
      swipeDirection="right"
      duration={toastConfig?.duration || 5000}
    >
      <ToastPrimitive.Root
        open={isOpen}
        onOpenChange={setIsOpen}
        className={`flex items-center justify-between rounded gap-4 py-3 px-4 text-center body1 font-normal shadow-dp8 lg:w-auto ${toastTypes[toastConfig.type || "success"]
          } ${animationClass}`}

      >
        {iconMap[toastConfig?.type || "success"]}
        <ToastPrimitive.Description>
          {toastConfig?.description}
        </ToastPrimitive.Description>
        <ToastClose aria-label="Close" onClick={handleClose}>
          <IconCloseLg />
        </ToastClose>
      </ToastPrimitive.Root>
      <ToastPrimitive.Viewport className={`lg:translate-none fixed left-1/2 z-[900] h-auto w-[280px] -translate-x-1/2 
        ${position[toastConfig?.position?.desktop || "topRight"]}  
        ${position[toastConfig?.position?.mobile || "top"]}  
        lg:w-auto lg:-translate-x-0`} />
      </ToastPrimitive.Provider>
  );
};

export default Toast;


